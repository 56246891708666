import React from 'react'


import icon1 from "../../assets/icon/Shield Check.svg"
import icon2 from "../../assets/icon/Star.svg"
import icon3 from "../../assets/icon/Messages.svg"

import TitleSection from '../../components/TitleSection'
import Card from './components/Card'

function Achievement() {
    let TitleObject = {
        titleSection: "Get Started",
        titleMain: "Get Ready to",
        titleGradient: "Start",
        description: "Request a Demo Now",
    }


    let objectSection = [
        {
            id: "1",
            img: icon1,
            title: "Get in Touch",
            description: "Talk To Our Team Experience SecPath firsthand by scheduling a demo with our technical team. Discover the comprehensive features of our platform and witness how it can be customized to perfectly align with your specific requirements."
        }, {
            id: "2",
            img: icon2,
            title: "Demo",
            description: "Receive a complimentary instant report that summarizes your organization's exposed secrets. Our non-disruptive approach guarantees uninterrupted operations and delivers valuable insights into credentials."
        }, {
            id: "3",
            img: icon3,
            title: "Setup",
            description: "Your instance will be set up shortly, and real-time updates on your organization's dark/deep web exposure will be provided through your dashboard. You can have peace of mind knowing that we are committed to keeping you informed and secure."
        }
    ]

    return (
        <section>
            <div className="container container-scroll" id='achievement'>

                <TitleSection TitleObject={TitleObject} />

                <div className='grid-style-tow' >
                    <div className="explain-text" >
                        <h2 data-aos="fade-up" data-aos-duration="1100">
                        Get Started In 3 Steps

                        </h2>
                        <p data-aos="fade-up" data-aos-duration="1200">
                        Secpath operates as a breach notification and dark web monitoring platform, providing valuable assistance to startups and enterprises in preventing data breaches. Our approach involves the implementation of a hybrid technique that integrates AI to address potential security risks.

                        </p>
                        <a className='main-button' data-aos="fade-up" data-aos-duration="1000" href="#contact-us">
                            Contact Us
                        </a>
                    </div>
                    <div className='grid-style-one' >


                        {
                            objectSection.map((item) => {
                                return (
                                    <Card key={item.id} data={item} />
                                )
                            })
                        }

                    </div>
                </div>










            </div>
        </section>
    )
}

export default Achievement