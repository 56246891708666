import React from 'react'
import TitleSection from '../components/TitleSection'

import userSvg from "../assets/icon/user.svg"
import phoneSvg from "../assets/icon/phone.svg"
import emailSvg from "../assets/icon/envelope.svg"
import messageSvg from "../assets/icon/message-dots.svg"

import { useState } from 'react'

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import axios from "axios"


import ErrorAlert from '../components/alertCopm/ErrorAlert'
import SuccessAlert from '../components/alertCopm/SuccessAlert'

function ContactUs() {

    let BASE_URL = process.env.REACT_APP_API_LINK



    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [gmail, setGmail] = useState("")
    const [message, setMessage] = useState("")

    let [errorAlertMessage, setErrorAlertMessage] = useState("")
    let [successAlertMessage, setSuccessAlertMessage] = useState("")
    let [toggleAlertError, setToggleAlertError] = useState(false)
    let [toggleAlertSucsses, setToggleAlertSucsses] = useState(false)

    let TitleObject = {
        titleSection: "Contact Us",
        titleMain: "Contact us to",
        titleGradient: "Secure Your Organization",
        description: "A member of our team will be in touch with you shortly Thank you for choosing secpath.",
    }


    let senMessageFunction = (e) => {
        e.preventDefault()
        axios.post(`${BASE_URL}/api/message`, { name, phone, gmail, message }).then(response => {
            if (response.data.error) {
                setErrorAlertMessage(response.data.error)
                setToggleAlertError(true)
                setTimeout(() => {
                    setToggleAlertError(false)
                }, 5000)
            } else {
                setSuccessAlertMessage(response.data.success)
                setToggleAlertSucsses(true)
                setTimeout(() => {
                    setToggleAlertSucsses(false)
                }, 5000)
                setName("")
                setPhone("")
                setGmail("")
                setMessage("")
            }
        })
    }


    return (
        <section>
        <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
        <SuccessAlert AlertMessage={successAlertMessage} toggleAlert={toggleAlertSucsses} />
            <div className="container container-scroll" id='contact-us'>
                <TitleSection TitleObject={TitleObject} />


                <div className='form-container'>
                    <form onSubmit={(e) => senMessageFunction(e)}>

                        <div className='name-phone-div' >
                            <div className='div-img-input' data-aos="fade-up" data-aos-duration="1000">
                                <img src={userSvg} alt='company-icon'  />
                                <input type="text" placeholder="Your Name" required value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className='div-img-input div-img-input-phone' data-aos="fade-up" data-aos-duration="1000">
                                <img src={phoneSvg} alt='company-icon' />

                                <PhoneInput country={'ca'} enableSearch={true} required value={phone} onChange={(phone) => setPhone(phone)} />
                            </div>
                        </div>

                        <div className='div-img-input' data-aos="fade-up" data-aos-duration="1000">
                            <img src={emailSvg} alt='company-icon' />
                            <input type="email" placeholder="Your Email" required value={gmail} onChange={(e) => setGmail(e.target.value)} />
                        </div>

                        <div className='div-img-input textarea-div' data-aos="fade-up" data-aos-duration="1000">
                            <img src={messageSvg} alt='company-icon' />
                            <textarea placeholder="Your Message" required value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                        </div>

                        <button type="submit" className='main-button' data-aos="fade-up" data-aos-duration="1000" >send Message </button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ContactUs