import React from 'react'
import TitleSection from '../../components/TitleSection'
import Card from './components/Card'
import icon from "../../assets/icon/arrow-down.svg"


function WhyUs() {
  let TitleObject = {
    titleSection: "Why Us",
    titleMain: "Why SecPath is the Leader in Darknet Data",
    titleGradient: "SecPath Features",
    description: "At SecPath, we take proactive steps to safeguard your digital assets by monitoring the dark web. Our process is comprehensive, from collecting resources to delivering insightful reports to your customer portal.",
  }
  let objectSection = [
    {
      id: "1",
      icon: icon,
      
      title: "Comprehensive coverage",
      description: "Our monitoring engine is designed to thoroughly analyze a wide range of markets, including those found on the Surface, Deep, and Dark Web, as well as Hacking Forums, public source code repositories, Paste Sites, Private Clouds, Telegram, Discord, Tor websites, and more. We aim to gather a vast amount of data to offer you a comprehensive protection mechanism."

    }, {
      id: "2",
      icon: icon,
      title: "AI & Human Intelligence",
      description: "Our organization employs advanced techniques, utilizing both artificial and human intelligence, to efficiently collect breached databases and compromised devices. Our state-of-the-art technology enables us to detect and analyze data breaches, identify the source of the breach, and locate the compromised devices. Our team of experts is highly experienced in implementing cutting-edge methodologies and tools to ensure the safety and security of your sensitive information. We are committed to providing comprehensive solutions to mitigate the impact of such breaches. We strive to maintain a professional and respectful approach, and we assure you that your data is in safe hands."
    }, {
      id: "3",
      icon: icon,
      title: "Report to Your Customer Portal",
      description: "We deliver comprehensive reports directly to your customer portal, providing you with real-time updates on the dark web activities relevant to your business. These reports empower you to stay ahead of potential risks and take proactive measures to mitigate them ASAP.      "
    }, {
      id: "4",
      icon: icon,
      title: "Continuous monitoring is available 24/7.      ",
      description: "SecPath ensures continuous surveillance of security systems, providing timely alerts to our esteemed clients regarding potential security threats"
    }, {
      id: "5",
      icon: icon,
      title: "Affordable Plans",
      description: "SecPath provides cost-effective cybersecurity solutions that cater to businesses of all sizes. Our comprehensive suite of services is designed to protect organizations against cybercrime, online fraud, and malware attacks. We take pride in our commitment to deliver the highest level of security, ensuring that our clients' sensitive data and operations are safeguarded.      "

    }, {
      id: "6",
      icon: icon,
      title: "Continuous Improvement and Support",
      description: "Our commitment to excellence doesn't stop with the initial setup. We continuously refine our processes and adapt to emerging threats to ensure that your dark web monitoring remains effective. Additionally, our dedicated support team is always available to address any questions or concerns you may have along the way."
    },
  ]

  return (
    <section>
      <div className="container container-scroll" id='why-us'>
        <TitleSection TitleObject={TitleObject} />
        <div className="grid-why-us">
          {objectSection.map((item) => {
            return (<Card key={item.id} data={item} />)
          })}
        </div>
      </div>
    </section>
  )
}

export default WhyUs