import React from 'react'
import logoImg from "../assets/logo/logo.png"
import gitHubImg from "../assets/icon/github.svg"
import twitterImg from "../assets/icon/twitter.svg"
import linkedinInImg from "../assets/icon/linkedin-in.svg"

import ErrorAlert from '../components/alertCopm/ErrorAlert'
import SuccessAlert from '../components/alertCopm/SuccessAlert'

import { useState } from 'react'

import axios from "axios"

function Footer() {

    let BASE_URL = process.env.REACT_APP_API_LINK

    const [gmail, setGmail] = useState("")

    let [errorAlertMessage, setErrorAlertMessage] = useState("")
    let [successAlertMessage, setSuccessAlertMessage] = useState("")
    let [toggleAlertError, setToggleAlertError] = useState(false)
    let [toggleAlertSucsses, setToggleAlertSucsses] = useState(false)




    let senMessageFunction = (e) => {
        e.preventDefault()
        axios.post(`${BASE_URL}/api/gmail`, {  gmail }).then(response => {
            if (response.data.error) {
                setErrorAlertMessage(response.data.error)
                setToggleAlertError(true)
                setTimeout(() => {
                    setToggleAlertError(false)
                }, 5000)
            } else {
                setSuccessAlertMessage(response.data.success)
                setToggleAlertSucsses(true)
                setTimeout(() => {
                    setToggleAlertSucsses(false)
                }, 5000)
              
                setGmail("")
               
            }
        })
    }

    return (
        <footer>
    <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
        <SuccessAlert AlertMessage={successAlertMessage} toggleAlert={toggleAlertSucsses} />
            <div className='container' >
                <div className='row-container' >
                    <div className='row-div' >
                        <div className="logo-about-div">
                            <a href="#" className='a-logo'><img src={logoImg} alt="logo.png" /></a>
                        </div>
                        <p>Replace outdated PDF pentest reports with a cutting-edge penetration testing solution.</p>
                        <form className="subscription-form" onSubmit={(e) => senMessageFunction(e)}>
                            <input type="email" className="input-subscription" placeholder="Your Email" required value={gmail} onChange={(e) => setGmail(e.target.value)} />
                            <button type="submit" className="submit-subscription default-button">
                                Get Started
                            </button>
                        </form>
                    </div>

                    <div className='row-div' >
                        <h2>Company</h2>
                        <div className='links-footer' >
                            <a href='#'>Products</a>
                            <a href='#'>Pricing</a>
                            <a href='#'>Blogs</a>
                            <a href='#'>Contact-Us</a>
                        </div>
                    </div>
                    <div className='row-div' >
                        <h2>Resources</h2>
                        <div className='links-footer' >

                            <a href='#'>Documentation</a>
                            <a href='#'>FAQ</a>
                            <a href='#'>Careers</a>
                            <a href='#'>Privacy Policy</a>
                        </div>
                    </div>
                    <div className='row-div' >
                        <h2>Contact us</h2>
                        <div className='links-footer' >
                            <a href='#'>team@Secpath.io</a>
                            <a href='#'>FAQ</a>
                            <p>
                                548 Market St San Francisco, California, PO Box 7775 United States
                            </p>
                        </div>
                    </div>
                </div>
                <div className='row-container-footer' >

                    <div className="contact-email">

                        <p>
                        © 2024 Secpath, Inc
                        </p>
                        <ul className="social-media">
                            <li>
                                <a href="#" target="_blank" rel="noopener noreferrer"> <img
                                    src={gitHubImg} alt="icons" className="icon" /></a>
                            </li>
                            <li>
                                <a href="#" target="_blank" rel="noopener noreferrer"> <img
                                    src={twitterImg} alt="icons" className="icon" /></a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/secpath" target="_blank" rel="noopener noreferrer"> <img
                                    src={linkedinInImg} alt="icons" className="icon" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>





        </footer >
    )
}

export default Footer