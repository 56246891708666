import React from 'react'
import TitleSection from '../../components/TitleSection'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay } from "swiper";
import 'swiper/css';

import Card from '../certificates/components/Card'

import img1 from "../../assets/certificates/1.png"
import img2 from "../../assets/certificates/2.png"
import img3 from "../../assets/certificates/3.png"
import img4 from "../../assets/certificates/4.png"
import img5 from "../../assets/certificates/5.png"
import img6 from "../../assets/certificates/6.png"




function Certificates() {

    let TitleObject = {
        titleSection: "our Certificates",
        titleMain: "The Most Important ",
        titleGradient: "Certificates we have",
        description: "Our team consists of highly skilled and experienced individuals, all of whom hold internationally recognized certifications.",
    }

    let objectSection = [
        {
            id: "1",
            img: img1,
        }, {
            id: "2",
            img: img2,

        }, {
            id: "3",
            img: img3,

        }, {
            id: "4",
            img: img4,

        }, {
            id: "5",
            img: img5,

        }, {
            id: "6",
            img: img6,

        }
    ]

    return (
        <section>
            <div className="container container-scroll" id='certificates'>
                <TitleSection TitleObject={TitleObject} />
                <Swiper
                    spaceBetween={10}
                    slidesPerView={5}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    breakpoints={{
                        50: {
                            slidesPerView: 2,
                        },
                        576: {
                            slidesPerView: 4,
                        },
                        1000: {
                            slidesPerView: 5,
                        },
                        1500: {
                            slidesPerView: 5,
                        },
                    }}
                    data-aos="fade-up" data-aos-duration="1100"
                >
                    {
                        objectSection.map((item) => {
                            return (
                                <SwiperSlide key={item.id}>
                                    <Card  data={item} />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </section>
    )
}

export default Certificates