import React from 'react'


function Card({ data }) {

    let { img } = data

    return (


        <div className="company-div certificates-card card" data-aos="fade-up" data-aos-duration="1000">
            <img alt="img" src={img} />
        </div>

    )
}

export default Card