import React from 'react'
import TitleSection from '../../components/TitleSection'
import Card from "./components/Card"


function HowWeWork() {

    let TitleObject = {
        titleSection: "Dark Web Monitoring",
        titleMain: "Our solution for monitoring",
        titleGradient: "the Dark Web operates as follows",
        description: "At a high level, here's how our Dark Web monitoring solution works.     ",
    }

    let objectSection = [
        {
            id: "1",
            title: "Device Compromise",
            description: "The utilization of malware to compromise millions of devices using cracked software, torrent files, and other methods to extract information is a serious concern.",
        }, {
            id: "2",
            title: "Monitoring",
            description: "Hackers publish compromised devices stolen credentials in their exclusive marketplaces at various platforms We monitor the DarkWeb marketplaces, hacking forums, private clouds, underground channels, Telegram, Discord and paste sites.",
        }, {
            id: "3",
            title: "Hack The Hacker",
            description: " By exploiting vulnerabilities in Command-and-Control servers of various info-stealers, we collect as much of the stolen data as possible. ",
        }, {
            id: "4",
            title: "Real-time Breach Detection System",
            description: "Filter, validate and classify all the collected data using our automated pipelines and feed it into the SecPath database Deliver real-time alerts of data-leaks and breaches to our customers and assess them to eliminate the risk.            ",
        }
    ]

    return (
        <section>
            <div className="container container-scroll" id='how-work'>

                <TitleSection TitleObject={TitleObject} />


                <div  className='grid-style-four timeline-div'>
                    {
                        objectSection.map((item) => {
                            return (
                                <Card key={item.id} data={item} />
                            )
                        })
                    }

                </div>

            </div>
        </section>
    )
}

export default HowWeWork