import React from 'react'
import TitleSection from '../../components/TitleSection'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay } from "swiper";
import 'swiper/css';

import Card from '../hall-of-fames/components/Card'

import img1 from "../../assets/hall-of-fames/1.png"

import img3 from "../../assets/hall-of-fames/3.png"
import img6 from "../../assets/hall-of-fames/6.png"
import img8 from "../../assets/hall-of-fames/8.png"
import img9 from "../../assets/hall-of-fames/9.png"
import img10 from "../../assets/hall-of-fames/10.png"
import img14 from "../../assets/hall-of-fames/14.png"
import img15 from "../../assets/hall-of-fames/15.png"
import img16 from "../../assets/hall-of-fames/16.png"
import img17 from "../../assets/hall-of-fames/17.png"
import img18 from "../../assets/hall-of-fames/18.png"
import img19 from "../../assets/hall-of-fames/19.png"
import img20 from "../../assets/hall-of-fames/20.png"
import img21 from "../../assets/hall-of-fames/21.png"
import img22 from "../../assets/hall-of-fames/22.png"
import img23 from "../../assets/hall-of-fames/23.png"
import img24 from "../../assets/hall-of-fames/24.png"
import img25 from "../../assets/hall-of-fames/25.png"
import img26 from "../../assets/hall-of-fames/26.png"
import img27 from "../../assets/hall-of-fames/27.png"
import img28 from "../../assets/hall-of-fames/28.png"
import img29 from "../../assets/hall-of-fames/29.png"
import img30 from "../../assets/hall-of-fames/30.png"
import img31 from "../../assets/hall-of-fames/31.png"
import img32 from "../../assets/hall-of-fames/32.png"
import img34 from "../../assets/hall-of-fames/33.png"
import img35 from "../../assets/hall-of-fames/34.png"
import img36 from "../../assets/hall-of-fames/35.png"
import img37 from "../../assets/hall-of-fames/36.png"
import img38 from "../../assets/hall-of-fames/37.png"
import img39 from "../../assets/hall-of-fames/38.png"


function HallOfFames() {

    let TitleObject = {
        titleSection: "Hall Of Fames",
        titleMain: "The Most important",
        titleGradient: "Hall Of Fames",
        description: "Our team members have been recognized and rewarded by companies for identifying and reporting security vulnerabilities in their products. ",
    }

    let objectSection = [
        {
            id: "1",
            img: img1,
        }, {
            id: "3",
            img: img3,

        }, {
            id: "6",
            img: img6,

        }, {
            id: "8",
            img: img8,
        }, {
            id: "9",
            img: img9,
        }, {
            id: "10",
            img: img10,
        },
         {
            id: "14",
            img: img14,
        }, {
            id: "15",
            img: img15,
        }, {
            id: "16",
            img: img16,
        }, {
            id: "17",
            img: img17,
        },{
            id: "18",
            img: img18,
        }, {
            id: "19",
            img: img19,
        }, {
            id: "20",
            img: img20,
        }, {
            id: "21",
            img: img21,
        }, {
            id: "22",
            img: img22,
        }, {
            id: "23",
            img: img24,
        }, {
            id: "123",
            img: img23,
        }, {
            id: "24",
            img: img25,
        }, {
            id: "25",
            img: img26,
        }, {
            id: "27",
            img: img27,
        }, {
            id: "28",
            img: img28,
        }, {
            id: "29",
            img: img29,
        }, {
            id: "30",
            img: img30,
        }, {
            id: "31",
            img: img31,
        }, {
            id: "32",
            img: img32,
        }, {
            id: "34",
            img: img34,
        }, {
            id: "35",
            img: img35,
        }, {
            id: "36",
            img: img36,
        }, {
            id: "37",
            img: img37,
        }, {
            id: "38",
            img: img38,
        }, {
            id: "39",
            img: img39,
        }
    ]

    return (
        <section>
            <div className="container container-scroll" id='hall-of-fames'>
                <TitleSection TitleObject={TitleObject} />
                <Swiper
                    spaceBetween={10}
                    slidesPerView={5}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    breakpoints={{
                        50: {
                            slidesPerView: 2,
                        },
                        576: {
                            slidesPerView: 4,
                        },
                        1000: {
                            slidesPerView: 5,
                        },
                        1500: {
                            slidesPerView: 5,
                        },
                    }}

                    data-aos="fade-up" data-aos-duration="1100"
                >
                    {
                        objectSection.map((item) => {
                            return (
                                <SwiperSlide key={item.id}>
                                    <Card data={item} />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </section>
    )
}

export default HallOfFames
