import React from 'react'
import TitleSection from '../../components/TitleSection'

import icon1 from "../../assets/icon/Chip Shield.svg"
import icon2 from "../../assets/icon/Cloud Lock.svg"
import icon3 from "../../assets/icon/Incognito.svg"
import icon4 from "../../assets/icon/Firewall.svg"
import icon5 from "../../assets/icon/Browser Protection.svg"
import icon6 from "../../assets/icon/Bug Shield.svg"


import Card from './components/Card'

function ServicesSection() {


    let TitleObject = {
        titleSection: "Our Services",
        titleMain: "The Most important services",
        titleGradient: "we provide",
        description: "Our experienced team offers a wide range of cybersecurity services contact us for our full services catalog. ",
    }

    let objectSection = [
        {
            id: "1",
            img: icon1,
            title: "Dark Web Monitoring",
            description: "Our system monitors your company's assets for leaks 24/7 across the surface, deep, and dark web, ensuring the safety of your sensitive data.With our automated monitoring of the surface, deep & dark web, your company assets are tracked 24/7 for data leaks."
        }, {
            id: "2",
            img: icon2,
            title: "Security Awareness Program",
            description: "Ensure the security of your organization by educating and engaging your employees at all levels with our customized security awareness programs. Our programs include phishing simulations and interactive training modules to equip your team with the necessary skills and knowledge to identify and mitigate security threats effectively."
        }, {
            id: "3",
            img: icon3,
            title: "Security Assessment",
            description: "We identify and address hidden security vulnerabilities within your application through rigorous penetration testing. Our tests encompass mobile apps, web applications, infrastructure, Desktop Apps, red team engagements, and social engineering."
        }, {
            id: "4",
            img: icon4,
            title: "Attack Surface Mapping",
            description: "We offer an attack surface mapping service that identifies vulnerabilities in your digital assets and services, helping you improve your security measures and protect your assets from potential threats."
        }, {
            id: "5",
            img: icon5,
            title: "Brand Protection",
            description: "To protect your brand, you need to analyze risks on the darknet, including counterfeit goods, insider threats, and data leaks Investigating the darknet and using open-source intelligence (OSINT) can provide a complete understanding of the risks associated with brand protection, allowing businesses to take proactive measures to mitigate them. "
        }, {
            id: "6",
            img: icon6,
            title: "Automated Threat Intelligence",
            description: "Investigate, using darknet and open-source tools and techniques, specific threat actors, groups, activities or assets. Research their darknet footprint, tactics, techniques, and procedures (TTPs) and victims to provide a summary of their activities and assess the threat posed to organizations and actionable threat intelligence which can be used to prevent future attacks and more.              "
        },
    ]

    return (
        <section>
            <div className='container container-scroll' id='services'>
                <TitleSection TitleObject={TitleObject} />
                <div className="services-grid grid-style" >

                    {
                        objectSection.map((item) => {
                            return (
                                <Card key={item.id} data={item} />
                            )
                        })
                    }


                </div>
            </div>
        </section>
    )
}

export default ServicesSection