import React from 'react'
import CirclePng from "../assets/element/circle.png"
function HeroSection() {
    return (

        <main className="main-sec sec">
            <div className="container">
                <div className='main-contact'>
                    <div className="h1-text-div">
                        
                        <h1 className="h1-text " data-glitch="SecPath" data-aos="fade-up" data-aos-duration="1000">
                            <span className="color-text">
                                Secpath</span>
                        </h1>
                        
                        <h2 data-aos="fade-up" data-aos-duration="1100">Unleash Cyber Power, Defend Your Domain</h2>

                        <p data-aos="fade-up" data-aos-duration="1100"> 
                        Secpath offers comprehensive cybersecurity solutions to protect businesses worldwide, Our services include dark web monitoring and penetration testing, designed to detect potential threats and ensure maximum security, Our team of experts provides tailored guidance and support to safeguard digital assets and reputation Contact us to learn more.
                        </p>
                    </div>
                    <a className='main-button' href="#contact-us" data-aos="fade-up" data-aos-duration="1000">
                        Contact Us
                    </a>
                </div>
                <a href='#services' aria-label="scroll to Services Section">
                    <lord-icon
                        src="https://cdn.lordicon.com/wtfdpwey.json"
                        trigger="loop"
                        colors="primary:#ffffff,secondary:#44a9e4"
                        style={{ width: '120px', height: '250px' }}
                    >
                    </lord-icon>
                </a>
            </div>
            <img src={CirclePng} alt="earth.png" className="earth-img" />

        </main>
    )
}

export default HeroSection